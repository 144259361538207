.App {
  /*text-align: center;*/
	background-color: var(--background-paper);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.fab {
  padding: .5vw;
}
.fab-container {
  position: absolute;
  padding: 1vw;
  display: flex;
  flex-direction: column;
}
.drawer {
  width: 25vw;
}
.home {
  display: flex;
}
.content-center {
  width: 60vw;
  height: 91.5vh;
  margin: 0 auto 0 auto;
  padding: 1vh 1vw;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.fade-overlay-top,
.fade-overlay-bottom {
	display: none;
	position: fixed;
	top: 68px;
	left: 0;
	bottom: auto;
	padding-top: 0.5rem;
	width: calc(100% - 0.5rem);
	border-color: transparent;
	min-height: 5vh;
	background-color: transparent !important;
	background-image: linear-gradient(var(--background-default) 0%, transparent 100%);
	padding-left: 0.5rem;
}

.fade-overlay-bottom {
	top: auto;
	bottom: 0;
	background-image: linear-gradient(transparent 0%, var(--background-default) 100%);
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

}

@media (max-width: 960px) {
  .drawer{
    width: 100vw;
  }
  .content-center {
    width: 100vw;
  }
}