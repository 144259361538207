.display-none {
    display: none !important;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.nav-bar {
    display: flex;
    font-size: calc(5px + 1.5vmin);
}

.navbar-background {
	background-color: transparent;
	max-width: 0 !important;
	padding: 0 !important;
}

.nav-menu {
	width: 100%;
	padding: 5%;
	top: 7vh;
	position: absolute;
	right: 0;
	padding: 1vw 2vw;
}
.nav-menu li {
	text-align: center;
	margin: 3% 0;
}
.nav-logo {
	margin-right: 15px;
	font-weight: normal;
	font-size: 1.3em;
}
.nav-links {
	margin: auto;
	flex-grow: 1;
}
.nav-link {
	margin: 1%;
	text-decoration: none !important;
}
.nav-link--active {
}
.nav-menu-container {
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 5px;
}
.nav-menu-container .nav-menu-left,
.nav-menu-container .nav-menu-right{

}
.nav-menu-container .nav-menu-left {
	margin-left: 25px;
}

.nav-menu-container .nav-menu-left button {
	color: var(--text-primary);
	margin: auto;
}


.nav-menu-container .nav-menu-center {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.right-drawer {
    width: 25vw;
}
.menu-btn-icon {
    height: 100%;
    width: 100%;
}
.account-menu-container {
	flex-grow: 0;
}
.account-info {
    margin-right: 2%;
}
.logout {
    border: 0;
}
.logout:hover {
    border-radius: calc(2px + 1vmin);
}
@media (max-width: 960px) {
    right-drawer {
        width: 100vw;
    }
}
