.auth-container {

}

.auth-form {
	width: 500px;
	height: 700px;
	margin: auto;
}
@media (max-width: 1024px) {
    .login-form {
        width: 75%
    }
}

@media (max-width: 900px) {
    .login-form {
        width: 90%
    }
}
