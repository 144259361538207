@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

body > #root > div {
  --height: 100vh;
  width: 100%;
}

body {
  margin: 0;
  font-family: Poppins, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

	/* prevent text selection */
	xxx-webkit-user-select: none; /* Safari */
	xxx-moz-user-select: none; /* Firefox */
	xxx-ms-user-select: none; /* Internet Explorer/Edge */
	xxxuser-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
