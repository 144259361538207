
.message {
	max-width: 75%;
	padding: 20px 15px;
}

.message-entering {
	transform: translateY(100%);
	transition: transform 0.2s ease-out, opacity 0.2s ease-out;
	opacity: 0;
}

.message-entered {
	transition: transform 0.2s ease-out, opacity 0.2s ease-out;
	opacity: 1;
}

.message p {
	padding: 0 0 15px 0;
	margin: 0;
	margin-block-end: 0 !important;
	margin-block-start: 0 !important;
	font-size: 16px;
}

.message p:last-child {
	padding-bottom: 0;
}

.message .message-body-divider {
	margin-left: -10px;
	margin-right: -10px;
}

.message-user {
	background-color: var(--background-default);
	color: var(--chat-user-text);
	border: 3px solid var(--chat-user-border) !important;
	border-radius: 15px;
	text-align: right;
}

.message-ai {
	position: relative;
	max-width: 100%;
	background-color: transparent;
	padding-left: 0;
	color: inherit;
	border: none;
}

.message-ai::before {
	background-image: url(/public/favicon-32x32.png);
	background-repeat: no-repeat;
	background-size: 85% 85%;
	width: 24px;
	height: 24px;
	position: relative;
	margin-top: 4px;
	margin-right: 20px;
}

@keyframes flash {
	0%, 100% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
}

.message-user-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.message-ai-wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	margin: 20px 0;
}


.message-ai-wrapper .markdown p {
	display: inline-block;
	font-size: 18px;
}

.message-ai-wrapper .markdown li {
	font-size: 18px;
}

.message-ai-wrapper .markdown.streaming {
	display: inline !important; /* Ensure the markdown text is treated as inline */
}

.message-ai-wrapper .markdown.streaming::after {
	content: '●'; /* The disc symbol */
	font-size: 24px; /* Adjust this to change the size of the disc */
	display: inline-block !important; /* Keep the disc on the same line */
	margin-left: 5px;
	margin-top: -3px;
	vertical-align: middle; /* Align the disc properly with the text */
}

.message-ai-wrapper .markdown ul,
.message-ai-wrapper .markdown ol {
	padding: 0 0 15px 0;
}

.message-ai-wrapper .markdown ol li,
.message-ai-wrapper .markdown ul li {
	margin-left: 21px;
	padding-bottom: 10px;
}

.message-ai-wrapper .markdown ul li {
	list-style: disc;
}

.message-ai-wrapper .markdown.flashing a[href="#cursor"],
.message-ai-wrapper.typing-indicator .markdown a[href="#cursor"] {
	font-size: 1.7em;
	line-height: 0;
	color: inherit;
	text-decoration: none;
	background: transparent;
	cursor: default;
	animation: flash 1s steps(1) infinite;
}

.message-ai-wrapper .message {
	max-width: 100% !important;
}

.message-ai-wrapper .message p {
	font-size: 18px !important;
}

@media (max-width: 900px) {
	.message {

	}

	.message p {
		font-size: 15px
	}

	.message-ai-wrapper {
		margin: 0;
	}

	.message-ai-wrapper .message p {
		font-size: 15px !important;
	}

	.message-ai-wrapper .markdown p {
		font-size: 15px;
	}

	.message-ai-wrapper .markdown li {
		font-size: 15px;
	}
}
