body {
	--background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Asvgjs%3D%22http%3A%2F%2Fsvgjs.dev%2Fsvgjs%22%20viewBox%3D%220%200%20700%20700%22%20width%3D%22700%22%20height%3D%22700%22%20opacity%3D%220.21%22%3E%3Cdefs%3E%3CradialGradient%20id%3D%22ffflux-gradient%22%3E%3Cstop%20offset%3D%220%25%22%20stop-color%3D%22hsl(89%2C%2086%25%2C%2044%25)%22%3E%3C%2Fstop%3E%3Cstop%20offset%3D%22100%25%22%20stop-color%3D%22hsl(211%2C%2074%25%2C%2062%25)%22%3E%3C%2Fstop%3E%3C%2FradialGradient%3E%3Cfilter%20id%3D%22ffflux-filter%22%20x%3D%22-20%25%22%20y%3D%22-20%25%22%20width%3D%22140%25%22%20height%3D%22140%25%22%20filterUnits%3D%22objectBoundingBox%22%20primitiveUnits%3D%22userSpaceOnUse%22%20color-interpolation-filters%3D%22sRGB%22%3E%3CfeTurbulence%20type%3D%22fractalNoise%22%20baseFrequency%3D%220.005%200.003%22%20numOctaves%3D%222%22%20seed%3D%22188%22%20stitchTiles%3D%22stitch%22%20x%3D%220%25%22%20y%3D%220%25%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20result%3D%22turbulence%22%3E%3C%2FfeTurbulence%3E%3CfeGaussianBlur%20stdDeviation%3D%2220%200%22%20x%3D%220%25%22%20y%3D%220%25%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20in%3D%22turbulence%22%20edgeMode%3D%22duplicate%22%20result%3D%22blur%22%3E%3C%2FfeGaussianBlur%3E%3CfeBlend%20mode%3D%22color-dodge%22%20x%3D%220%25%22%20y%3D%220%25%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20in%3D%22SourceGraphic%22%20in2%3D%22blur%22%20result%3D%22blend%22%3E%3C%2FfeBlend%3E%3C%2Ffilter%3E%3C%2Fdefs%3E%3Crect%20width%3D%22700%22%20height%3D%22700%22%20fill%3D%22url(%23ffflux-gradient)%22%20filter%3D%22url(%23ffflux-filter)%22%3E%3C%2Frect%3E%3C%2Fsvg%3E') !important;
	--background-image: url('/public/white-teal-abstract-bg.svg') !important;
	background-repeat: no-repeat !important;
	background-size: 100% !important;
}

.prepkit-summary-container {
	display: flex;
	position: fixed;
	max-height: calc(100vh - (64px + 40px)) !important;
	padding: 100px 40px 0px 40px;
	border: 0px solid purple;
	--justify-content: flex-start;
	height: 100%;

}

.prepkit-summary {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	border-radius: 20px;
	background-color: var(--background-default);
	padding-bottom: 20px;
	box-shadow: 0 50px 70px rgba(0,0,0,0.15);
}

.prepkit-summary-header {
	text-align: center;
	font-weight: bold;
	padding: 15px;
}

.prepkit-summary-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: auto;
	padding: 0 !important;
	border: 0px solid red;
	background-color: var(--background-default);
}

.prepkit-summary-card {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	border: 2px solid var(--background-default);
	padding: 10px 20px !important;
	margin-bottom: 10px;
	border-radius: 10px;
	background-color: var(--background-default);
}

.prepkit-summary-card:hover {
	border: 2px solid var(--primary-light);
}


.prepkit-summary-card .score {
	text-align: center;
	color: white;
	border-radius: 10px;
	width: 65px;
	font-size: 20px;
}

.prepkit-summary-card.score-success .score {
	background-color: var(--score-success)
}
.prepkit-summary-card.score-warning .score {
	background-color: var(--score-warning)
}
.prepkit-summary-card.score-error .score {
	background-color: var(--score-error)
}


/* Style the scrollbar to be transparent by default and show it on hover only */
.prepkit-summary-content::-webkit-scrollbar {
	width: 10px;
	opacity: 0;
	transition: opacity 0.3s ease, background 0.3s ease;
}

.prepkit-summary-content::-webkit-scrollbar-thumb {
	background: var(--background-paper);
	border-radius: 6px;
}

.prepkit-summary-content:hover::-webkit-scrollbar {
	opacity: 1;
}

.prepkit-summary-content::-webkit-scrollbar-track {
	background: var(--background-paper);
}

.prepkit-summary-content:hover::-webkit-scrollbar-thumb {
	background: #aaa;
}
